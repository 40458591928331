import $ from './lib/jquery';
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')
// import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import TraversableMenu from 'traversable_menu';
import 'magnific-popup/dist/jquery.magnific-popup';
import Swiper from 'swiper/swiper-bundle';
import 'lazySizes';
import 'focus-visible/dist/focus-visible';

$( document ).ready(function() {

    /**
     * Name           : SkipLinks
     * Beschreibung   : Open Navigation on click on skip link
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    function focusFirstNavLink() {
        console.log("firstnavlink function triggered");
        setTimeout(function() {
            $('.sidebar').addClass('opened'); // Öffnet die Sidebar mit Verzögerung
            $('.navigation-layer .navigation-header a').first().focus(); // Fokussiert den ersten Link
        }, 100); // Verzögerung von 100 Millisekunden
    }
    // Event-Handler für Klick- und Tastaturereignisse auf dem Skiplink
    $('#skiplink-main-menu').on('click keydown', function(event) {
        event.preventDefault(); // Verhindert das Standardverhalten des Links
        console.log("Event type: " + event.type); // Ausgabe des Event-Typs
        if (event.type === 'click') {
            console.log("Skiplink clicked");
            focusFirstNavLink();
        }
        if (event.type === 'keydown' && event.key === 'Enter') {
            focusFirstNavLink();
        }
    });




    /**
     * Name           : Gallery
     * Beschreibung   : .
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery, swiper
     */
    const teaser = new Swiper('.teaser-gallery', {
        // Optional parameters
        loop: false,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1.66,
                spaceBetween: 20
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 2.4,
                spaceBetween: 30
            },
            // when window width is >= 720px
            720: {
                slidesPerView: 3.5,
                spaceBetween: 40
            }
        },
        slidesPerView: 'auto',
        spaceBetween: 50,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            type: "fraction",
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });

    /* Gallery */
    const gallery = new Swiper('.gallery', {
        // Optional parameters
        loop: false,
        slidesPerView: 'auto',
        spaceBetween: 30,
        // If we need pagination
        pagination: {
            el: '.swiper-pagination',
            type: "fraction",
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });





    /**
     * Name           : Exhibitions AJAX Load
     * Beschreibung   : -
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    $(".event_item").click(function(e) {
        // e.preventDefault();
        $("#exhibition").load($(this).attr("href"));
    });





    /**
     * Name           : Active Status for parent navi links (mobile only using Bootstrap breakpoints)
     * Beschreibung   : Fügt bei mobilen Ansichten 'show' und 'active' Klassen für Dropdowns hinzu, wenn ein untergeordnetes Element aktiv ist.
     *                  Die 'active'-Klasse bleibt auch auf Desktop erhalten, aber ohne das Dropdown-Menü zu öffnen.
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    function activateDropdownOnMobile() {
        // Funktion zur Überprüfung, ob es sich um eine mobile Ansicht handelt
        function isMobile() {
            return window.innerWidth < 992; // Prüft, ob die Fensterbreite kleiner als 992px ist (Bootstrap lg breakpoint)
        }

        $('.dropdown').each(function() {
            // Der aktive Navigationspunkt bleibt immer markiert (auch auf Desktop)
            if ($(this).find('.active').length !== 0) {
                $(this).find('.nav-link').addClass('active'); // Aktiv, unabhängig von der Ansicht

                if (isMobile()) {
                    // In der mobilen Ansicht das Dropdown-Menü automatisch aufklappen
                    $(this).find('.nav-link').addClass('show');
                    $(this).find('.dropdown-menu').addClass('show');
                } else {
                    // In der Desktop-Ansicht nur die 'show'-Klassen entfernen, das Menü bleibt geschlossen
                    $(this).find('.nav-link').removeClass('show');
                    $(this).find('.dropdown-menu').removeClass('show');
                }
            }
        });
    }

    // Initiales Anwenden bei Seitenladevorgang
    activateDropdownOnMobile();

    // Bei Größenänderung des Fensters (Resizing) anwenden
    $(window).resize(function() {
        activateDropdownOnMobile();
    });




    /**
     * Name           : MagnificPopup
     * Beschreibung   : PopUps und Lightbox für Artikelbilder, Hinweise und Formulare
     * Parameter      : Klasse des Elements
     * Rückgabe       : -
     * Bibliothek     : jQuery, Magnific Popup (magnific_min.js)
     */
    var groups = {};
    $('.mfp').each(function() {
        var id = parseInt($(this).attr('data-group'), 10);

        if(!groups[id]) {
            groups[id] = [];
        }

        groups[id].push( this );
    });

    $.each(groups, function() {

        $(this).magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            image: {
                markup: '<div class="mfp-figure">'+
                    '<div class="mpf-img-wrapper">'+
                    '<div class="mfp-close-wrapper"><div class="mfp-close"></div></div>'+
                    '<div class="mfp-img"></div>'+
                    '</div>'+
                    '<div class="mfp-bottom-bar">'+
                    '<div class="mfp-title"></div>'+
                    '<div class="mfp-counter"></div>'+
                    '</div>'+
                    '</div>',

            },
            gallery: {
                enabled: true,
                tCounter: '%curr% von %total%' // markup of counter
            },
            callbacks: {
                markupParse: function(template, values, item) {
                    // Dynamically insert the counter HTML into the template
                    template.find('.mfp-counter').html('<span class="mfp-counter">' + values.counter + '</span>');

                    // Dynamically insert the title HTML into the template
                    var caption_text = item.el.data('caption') || '';
                    var caption_copyright = item.el.data('copyright') ? ' - ' + item.el.data('copyright') : '';
                    var title_html = '<div class="mfp-title-wrapper">' + caption_text + '<span class="mfp-src"> ' + caption_copyright + '</span></div>';

                    if (caption_text === '' && caption_copyright === '') {
                        title_html = '<div class="mfp-title-wrapper" style="display:none;"></div>';
                    }

                    template.find('.mfp-title').html(title_html);
                }
            }
        })

    });




    /**
     * Name           : Form Clear-Button for Input Field
     * Beschreibung   : Add Clear-Button to input field
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    $('.has-clear input[type="text"]').after("<span class=\"form-control-clear d-none\"></span>").on('input propertychange', function() {
        var $this = $(this);
        var visible = Boolean($this.val());
        $this.siblings('.form-control-clear').toggleClass('d-none', !visible);
        if(!$this.parent().hasClass('keepsubmit')) {
            $this.siblings('.form-control-submit').toggleClass('d-none', visible);
        }
    }).trigger('propertychange');

    $('.form-control-clear').click(function() {
        // console.log('clear1');
        $(this).siblings('input[type="text"]').val('').trigger('propertychange').focus();
    });



    /**
     * Name           : Navigation
     * Beschreibung   : .
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    // Variable zum Speichern des ursprünglichen Logos
    let originalLogoSrc = $('#logoImage_mobile').attr('src');
    var navbarNav = document.getElementById('navbarNav');
    navbarNav.addEventListener('show.bs.collapse', function() {
        console.log("Event: show.bs.collapse");
        document.getElementById('main-menu-wrapper').classList.add('show');
        // Logo ändern
        $('#logoImage_mobile').attr('src', '/assets/global/img/logo_black_mobile.svg'); // Bild wechseln

    });

    navbarNav.addEventListener('hide.bs.collapse', function() {
        console.log("Event: hidden.bs.collapse");
        document.getElementById('main-menu-wrapper').classList.remove('show');
        // Ursprüngliches Logo wieder nutzen
        $('#logoImage_mobile').attr('src', originalLogoSrc); // Ursprüngliches Bild wiederherstellen
    });




    /**
     * Name           : Suche
     * Beschreibung   : Fokus auf Suchfeld setzen, wenn das Collapse geöffnet wird
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery
     */
    var offcanvasSearch = document.getElementById('offcanvasSearch');
    var searchField = document.getElementById('autocomplete');

    offcanvasSearch.addEventListener('shown.bs.offcanvas', function () {
        searchField.focus();
    });





    /**
     * Name           : Newsletter2Go-Formular
     * Beschreibung   : fehlendes title-tag bei Datenschutz-Icon einfügen
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : -
     */
    setTimeout(function () {
        // Wähle das Bild anhand seines src-Attributs
        const img = document.querySelector('img[src="https://files.newsletter2go.com/forms/gdpr/gdpr_lock.png"]');

        if (img) {
            // Setze das title-Attribut
            img.setAttribute('title', 'Datenschutz-Icon');
            img.setAttribute('alt', 'Ein Schloss-Symbol zur Darstellung der DSGVO-Konformität');
        }
    }, 1000); // Verzögerung von 1 Sekunde





    /**
     * Name           : Scroll-to-top
     * Beschreibung   : -
     * Parameter      : -
     * Rückgabe       : -
     * Bibliothek     : jQuery, https://kulturbanause.de/blog/back-to-top-button-mit-jquery/
     */
        // Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.
    var back_to_top_button = ['<a href="#top" class="back-to-top"><span class="visually-hidden">Nach oben</span></a>'].join("");
    $("body").append(back_to_top_button)

    // Der Button wird ausgeblendet
    $(".back-to-top").hide();

    // Funktion für das Scroll-Verhalten
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) { // Wenn 100 Pixel gescrolled wurde
                $('.back-to-top').fadeIn();
            } else {
                $('.back-to-top').fadeOut();
            }
        });

        $('.back-to-top').click(function () { // Klick auf den Button
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });

});